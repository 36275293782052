import React from 'react';

export const Logo = ({ scaleFactor=1 }) => {
  return (
    <svg width={scaleFactor * 217} height={scaleFactor * 148} viewBox="0 0 217 148" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_227_85911)">
      <path d="M83.1016 17.5909C83.1016 12.8463 86.9478 9 91.6925 9V9C96.4371 9 100.283 12.8463 100.283 17.5909V39.6486H83.1016V17.5909Z" fill="#2684FC"/>
      </g>
      <g filter="url(#filter1_d_227_85911)">
      <rect x="133.5" y="21.0273" width="17.1818" height="63" rx="8.59091" transform="rotate(90 133.5 21.0273)" fill="#4A9AFF"/>
      </g>
      <g filter="url(#filter2_d_227_85911)">
      <rect x="133.5" y="41.6455" width="17.1818" height="63" rx="8.59091" transform="rotate(90 133.5 41.6455)" fill="#4A9AFF"/>
      </g>
      <g filter="url(#filter3_d_227_85911)">
      <path d="M83.2695 39.6484H100.297V63.4863C100.297 68.1882 96.4849 71.9998 91.783 71.9998V71.9998C87.0812 71.9998 83.2695 68.1882 83.2695 63.4863V39.6484Z" fill="#2684FC"/>
      </g>
      <g filter="url(#filter4_d_227_85911)">
      <path d="M103.703 39.6484H120.73V63.4863C120.73 68.1882 116.919 71.9998 112.217 71.9998V71.9998C107.515 71.9998 103.703 68.1882 103.703 63.4863V39.6484Z" fill="#2684FC"/>
      </g>
      <path d="M124.986 41.8623C129.688 41.8623 133.5 45.6739 133.5 50.3758V50.3758C133.5 55.0777 129.688 58.8893 124.986 58.8893L102.851 58.8893L102.851 41.8623L124.986 41.8623Z" fill="#4A9AFF"/>
      <g filter="url(#filter5_d_227_85911)">
      <path d="M103.719 17.5909C103.719 12.8463 107.565 9 112.31 9V9C117.054 9 120.901 12.8463 120.901 17.5909V39.6486H103.719V17.5909Z" fill="#2684FC"/>
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M103.791 65.6998C106.276 65.6998 108.291 63.6851 108.291 61.1998L108.291 47.2271C108.291 46.6748 108.738 46.2271 109.291 46.2271H122.118C124.603 46.2271 126.618 44.2124 126.618 41.7271V38.1271C126.618 35.6418 124.603 33.6271 122.118 33.6271H109.291C108.738 33.6271 108.291 33.1794 108.291 32.6271L108.291 19.7998C108.291 17.3145 106.276 15.2998 103.791 15.2998H100.191C97.7053 15.2998 95.6906 17.3145 95.6906 19.7998L95.6906 32.6271C95.6906 33.1794 95.2428 33.6271 94.6906 33.6271H81.8633C79.378 33.6271 77.3633 35.6418 77.3633 38.1271V41.7271C77.3633 44.2124 79.378 46.2271 81.8633 46.2271H94.6906C95.2428 46.2271 95.6906 46.6748 95.6906 47.2271L95.6906 61.1998C95.6906 63.6851 97.7053 65.6998 100.191 65.6998H103.791Z" fill="white"/>
      <path d="M10 126.36V95.64H15.6262V120.941H28.4507V126.36H10Z" fill="#0E121B"/>
      <path d="M44.7509 127C41.7723 127 39.2005 126.332 37.0355 124.995C34.8843 123.658 33.2226 121.788 32.0505 119.384C30.8922 116.98 30.313 114.186 30.313 111C30.313 107.814 30.8922 105.02 32.0505 102.616C33.2226 100.212 34.8843 98.3422 37.0355 97.0053C39.2005 95.6684 41.7723 95 44.7509 95C48.1708 95 51.039 95.8747 53.3557 97.624C55.6862 99.3733 57.3272 101.741 58.2787 104.728L52.6111 106.349C52.0595 104.486 51.1287 103.043 49.8186 102.019C48.5086 100.98 46.8194 100.461 44.7509 100.461C42.8617 100.461 41.2828 100.895 40.0141 101.763C38.7592 102.63 37.8146 103.853 37.1803 105.432C36.546 107.011 36.2288 108.867 36.2288 111C36.2288 113.133 36.546 114.989 37.1803 116.568C37.8146 118.147 38.7592 119.37 40.0141 120.237C41.2828 121.105 42.8617 121.539 44.7509 121.539C46.8194 121.539 48.5086 121.02 49.8186 119.981C51.1287 118.943 52.0595 117.5 52.6111 115.651L58.2787 117.272C57.3272 120.259 55.6862 122.627 53.3557 124.376C51.039 126.125 48.1708 127 44.7509 127Z" fill="#0E121B"/>
      <path d="M62.8332 126.36V95.64H75.4095C75.699 95.64 76.0852 95.6542 76.5678 95.6827C77.0642 95.6969 77.5055 95.7396 77.8916 95.8107C79.6705 96.0951 81.1253 96.6996 82.2561 97.624C83.4006 98.5484 84.2418 99.7147 84.7796 101.123C85.3174 102.516 85.5863 104.074 85.5863 105.795C85.5863 108.369 84.9658 110.573 83.7247 112.408C82.4836 114.228 80.5392 115.352 77.8916 115.779L75.4095 115.949H68.4594V126.36H62.8332ZM79.6291 126.36L73.7547 113.859L79.5464 112.707L86 126.36H79.6291ZM68.4594 110.531H75.1612C75.4508 110.531 75.768 110.516 76.1127 110.488C76.4575 110.46 76.7746 110.403 77.0642 110.317C77.8227 110.104 78.4087 109.748 78.8224 109.251C79.2361 108.739 79.5188 108.177 79.6705 107.565C79.836 106.94 79.9187 106.349 79.9187 105.795C79.9187 105.24 79.836 104.657 79.6705 104.045C79.5188 103.42 79.2361 102.858 78.8224 102.36C78.4087 101.848 77.8227 101.485 77.0642 101.272C76.7746 101.187 76.4575 101.13 76.1127 101.101C75.768 101.073 75.4508 101.059 75.1612 101.059H68.4594V110.531Z" fill="#0E121B"/>
      <circle cx="100.5" cy="110.5" r="3.5" fill="url(#paint0_linear_227_85911)"/>
      <path d="M116 124.42V96.5614H126.278C126.537 96.5614 126.826 96.5743 127.147 96.6001C127.479 96.613 127.812 96.6517 128.145 96.7162C129.537 96.9355 130.714 97.4449 131.675 98.2445C132.649 99.0313 133.382 100.024 133.875 101.224C134.38 102.423 134.633 103.752 134.633 105.209C134.633 106.654 134.38 107.976 133.875 109.175C133.37 110.374 132.631 111.374 131.657 112.174C130.696 112.96 129.525 113.463 128.145 113.683C127.812 113.734 127.479 113.773 127.147 113.799C126.826 113.824 126.537 113.837 126.278 113.837H118.717V124.42H116ZM118.717 111.129H126.204C126.426 111.129 126.684 111.116 126.98 111.09C127.276 111.064 127.566 111.019 127.849 110.955C128.761 110.748 129.507 110.355 130.086 109.775C130.677 109.194 131.115 108.504 131.398 107.705C131.694 106.905 131.842 106.073 131.842 105.209C131.842 104.345 131.694 103.513 131.398 102.713C131.115 101.901 130.677 101.204 130.086 100.624C129.507 100.044 128.761 99.6503 127.849 99.444C127.566 99.3795 127.276 99.3408 126.98 99.3279C126.684 99.3021 126.426 99.2892 126.204 99.2892H118.717V111.129Z" fill="#0E121B"/>
      <path d="M138.682 124.42V96.5614H141.399V121.692H154.043V124.42H138.682Z" fill="#0E121B"/>
      <path d="M167.583 125C165.55 125 163.751 124.561 162.186 123.684C160.633 122.807 159.413 121.569 158.526 119.97C157.638 118.371 157.195 116.501 157.195 114.36V96.5808L159.93 96.5614V113.992C159.93 115.437 160.165 116.688 160.633 117.745C161.101 118.79 161.711 119.654 162.463 120.338C163.227 121.008 164.059 121.505 164.958 121.827C165.858 122.15 166.733 122.311 167.583 122.311C168.446 122.311 169.327 122.15 170.227 121.827C171.126 121.505 171.952 121.008 172.704 120.338C173.455 119.654 174.065 118.79 174.534 117.745C175.002 116.688 175.236 115.437 175.236 113.992V96.5614H177.972V114.36C177.972 116.501 177.528 118.371 176.641 119.97C175.754 121.556 174.528 122.795 172.963 123.684C171.41 124.561 169.617 125 167.583 125Z" fill="#0E121B"/>
      <path d="M191.277 125C189.416 125 187.74 124.678 186.249 124.033C184.77 123.375 183.544 122.446 182.57 121.247C181.609 120.047 180.98 118.622 180.685 116.971L183.458 116.488C183.901 118.293 184.838 119.712 186.267 120.744C187.697 121.763 189.404 122.272 191.388 122.272C192.694 122.272 193.865 122.059 194.9 121.634C195.935 121.195 196.748 120.576 197.34 119.777C197.931 118.977 198.227 118.029 198.227 116.933C198.227 116.21 198.104 115.598 197.858 115.095C197.611 114.579 197.284 114.153 196.878 113.818C196.471 113.483 196.027 113.205 195.547 112.986C195.066 112.754 194.598 112.567 194.142 112.425L187.395 110.316C186.582 110.071 185.83 109.762 185.14 109.388C184.45 109.001 183.846 108.543 183.328 108.014C182.823 107.473 182.429 106.841 182.145 106.118C181.862 105.396 181.72 104.571 181.72 103.642C181.72 102.069 182.114 100.708 182.903 99.5601C183.692 98.4122 184.77 97.5287 186.138 96.9097C187.506 96.2906 189.065 95.9875 190.815 96.0004C192.589 96.0004 194.173 96.3293 195.565 96.987C196.97 97.6448 198.129 98.5863 199.041 99.8116C199.953 101.024 200.556 102.462 200.852 104.126L198.005 104.667C197.821 103.455 197.395 102.404 196.73 101.514C196.064 100.624 195.214 99.9405 194.179 99.4633C193.156 98.9732 192.029 98.7217 190.796 98.7088C189.601 98.7088 188.535 98.9216 187.598 99.3472C186.674 99.76 185.941 100.327 185.398 101.05C184.856 101.772 184.585 102.597 184.585 103.526C184.585 104.467 184.832 105.222 185.325 105.789C185.83 106.357 186.446 106.802 187.173 107.124C187.912 107.447 188.627 107.711 189.317 107.918L194.53 109.523C195.072 109.678 195.707 109.904 196.434 110.2C197.174 110.484 197.888 110.897 198.578 111.438C199.281 111.967 199.86 112.664 200.316 113.528C200.772 114.392 201 115.488 201 116.817C201 118.106 200.754 119.261 200.261 120.28C199.78 121.298 199.096 122.156 198.209 122.853C197.334 123.549 196.305 124.078 195.122 124.439C193.951 124.813 192.669 125 191.277 125Z" fill="#0E121B"/>
      <defs>
      <filter id="filter0_d_227_85911" x="78.9016" y="5.8" width="25.5816" height="39.0484" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_227_85911"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_227_85911" result="shape"/>
      </filter>
      <filter id="filter1_d_227_85911" x="67.3" y="18.8273" width="69.4" height="23.5816" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1.6"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_227_85911"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_227_85911" result="shape"/>
      </filter>
      <filter id="filter2_d_227_85911" x="67.3" y="39.4455" width="69.4" height="23.5816" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1.6"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_227_85911"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_227_85911" result="shape"/>
      </filter>
      <filter id="filter3_d_227_85911" x="79.0695" y="36.4484" width="25.4273" height="40.7516" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_227_85911"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_227_85911" result="shape"/>
      </filter>
      <filter id="filter4_d_227_85911" x="99.5031" y="36.4484" width="25.4273" height="40.7516" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_227_85911"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_227_85911" result="shape"/>
      </filter>
      <filter id="filter5_d_227_85911" x="99.5188" y="5.8" width="25.5816" height="39.0484" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2.1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_227_85911"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_227_85911" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_227_85911" x1="100.5" y1="107" x2="100.5" y2="114" gradientUnits="userSpaceOnUse">
      <stop stop-color="#85BBFF"/>
      <stop offset="1" stop-color="#2784FC"/>
      </linearGradient>
      </defs>
    </svg>
  );
};
