import React from 'react';

export const LogoHorizontal = ({ scaleFactor=1 }) => {
  return (
    <svg width={scaleFactor * 145} height={scaleFactor * 33} viewBox="0 0 145 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_391_4570)">
    <path d="M8.60156 7.13637C8.60156 5.4042 10.0058 4 11.7379 4C13.4701 4 14.8743 5.4042 14.8743 7.13636V15.1892H8.60156V7.13637Z" fill="#2684FC"/>
    </g>
    <g filter="url(#filter1_d_391_4570)">
    <rect x="27" y="8.39062" width="6.27273" height="23" rx="3.13636" transform="rotate(90 27 8.39062)" fill="#4A9AFF"/>
    </g>
    <g filter="url(#filter2_d_391_4570)">
    <rect x="27" y="15.918" width="6.27273" height="23" rx="3.13636" transform="rotate(90 27 15.918)" fill="#4A9AFF"/>
    </g>
    <g filter="url(#filter3_d_391_4570)">
    <path d="M8.66406 15.1895H14.8803V23.8922C14.8803 25.6087 13.4887 27.0003 11.7722 27.0003C10.0556 27.0003 8.66406 25.6087 8.66406 23.8922V15.1895Z" fill="#2684FC"/>
    </g>
    <g filter="url(#filter4_d_391_4570)">
    <path d="M16.1211 15.1895H22.3373V23.8922C22.3373 25.6087 20.9458 27.0003 19.2292 27.0003C17.5126 27.0003 16.1211 25.6087 16.1211 23.8922V15.1895Z" fill="#2684FC"/>
    </g>
    <path d="M23.8919 15.998C25.6084 15.998 27 17.3896 27 19.1062C27 20.8227 25.6085 22.2143 23.8919 22.2143L15.8108 22.2143L15.8108 15.998L23.8919 15.998Z" fill="#4A9AFF"/>
    <g filter="url(#filter5_d_391_4570)">
    <path d="M16.1289 7.13637C16.1289 5.4042 17.5331 4 19.2653 4C20.9974 4 22.4016 5.4042 22.4016 7.13636V15.1892H16.1289V7.13637Z" fill="#2684FC"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4948 24.7008C16.7651 24.7008 17.7948 23.671 17.7948 22.4008V17.5917H22.1857C23.456 17.5917 24.4857 16.5619 24.4857 15.2917C24.4857 14.0214 23.456 12.9917 22.1857 12.9917H17.7948V8.60078C17.7948 7.33053 16.7651 6.30078 15.4948 6.30078C14.2246 6.30078 13.1948 7.33052 13.1948 8.60078L13.1948 12.9917H8.80391C7.53365 12.9917 6.50391 14.0214 6.50391 15.2917C6.50391 16.5619 7.53365 17.5917 8.80391 17.5917H13.1948L13.1948 22.4008C13.1948 23.671 14.2246 24.7008 15.4948 24.7008Z" fill="white"/>
    <path d="M43 23.6602V7.34H45.989V20.7815H52.8021V23.6602H43Z" fill="#0E121B"/>
    <path d="M61.4616 24.0002C59.8792 24.0002 58.513 23.6451 57.3628 22.9349C56.22 22.2246 55.3372 21.2311 54.7145 19.9542C54.0991 18.6773 53.7914 17.1926 53.7914 15.5001C53.7914 13.8076 54.0991 12.323 54.7145 11.0461C55.3372 9.76915 56.22 8.77558 57.3628 8.06535C58.513 7.35512 59.8792 7 61.4616 7C63.2785 7 64.8023 7.46467 66.033 8.39402C67.2711 9.32336 68.1429 10.5814 68.6484 12.1681L65.6374 13.0294C65.3444 12.0396 64.8499 11.2727 64.1539 10.7287C63.458 10.1772 62.5605 9.90137 61.4616 9.90137C60.458 9.90137 59.6192 10.1318 58.9452 10.5927C58.2785 11.0536 57.7767 11.7034 57.4397 12.5421C57.1027 13.3807 56.9342 14.3668 56.9342 15.5001C56.9342 16.6335 57.1027 17.6195 57.4397 18.4581C57.7767 19.2968 58.2785 19.9466 58.9452 20.4075C59.6192 20.8684 60.458 21.0988 61.4616 21.0988C62.5605 21.0988 63.458 20.8231 64.1539 20.2715C64.8499 19.7199 65.3444 18.953 65.6374 17.9708L68.6484 18.8321C68.1429 20.4188 67.2711 21.6769 66.033 22.6062C64.8023 23.5355 63.2785 24.0002 61.4616 24.0002Z" fill="#0E121B"/>
    <path d="M71.068 23.6602V7.34H77.7492C77.9031 7.34 78.1082 7.34756 78.3646 7.36267C78.6283 7.37023 78.8628 7.39289 79.0679 7.43067C80.0129 7.58178 80.7858 7.9029 81.3865 8.39402C81.9946 8.88513 82.4415 9.5047 82.7272 10.2527C83.0129 10.9932 83.1557 11.8205 83.1557 12.7347C83.1557 14.1023 82.8261 15.2734 82.1667 16.2481C81.5074 17.2152 80.4744 17.8121 79.0679 18.0388L77.7492 18.1295H74.057V23.6602H71.068ZM79.9909 23.6602L76.8701 17.0188L79.947 16.4068L83.3755 23.6602H79.9909ZM74.057 15.2508H77.6173C77.7712 15.2508 77.9397 15.2432 78.1228 15.2281C78.306 15.213 78.4745 15.1828 78.6283 15.1374C79.0312 15.0241 79.3426 14.8352 79.5624 14.5708C79.7822 14.2988 79.9323 14.0003 80.0129 13.6754C80.1008 13.343 80.1448 13.0294 80.1448 12.7347C80.1448 12.4401 80.1008 12.1303 80.0129 11.8054C79.9323 11.4729 79.7822 11.1745 79.5624 10.91C79.3426 10.638 79.0312 10.4454 78.6283 10.332C78.4745 10.2867 78.306 10.2565 78.1228 10.2414C77.9397 10.2263 77.7712 10.2187 77.6173 10.2187H74.057V15.2508Z" fill="#0E121B"/>
    <path d="M92.9375 15.2345C92.9375 16.2614 92.1051 17.0939 91.0781 17.0939C90.0512 17.0939 89.2188 16.2614 89.2188 15.2345C89.2188 14.2076 90.0512 13.3751 91.0781 13.3751C92.1051 13.3751 92.9375 14.2076 92.9375 15.2345Z" fill="url(#paint0_linear_391_4570)"/>
    <path d="M99.3126 22.6294V7.82951H104.773C104.91 7.82951 105.064 7.83636 105.234 7.85007C105.411 7.85692 105.588 7.87748 105.765 7.91173C106.504 8.02821 107.13 8.29886 107.64 8.72367C108.158 9.14163 108.547 9.66922 108.809 10.3064C109.077 10.9436 109.212 11.6494 109.212 12.4236C109.212 13.191 109.077 13.8933 108.809 14.5306C108.541 15.1678 108.148 15.6988 107.631 16.1236C107.12 16.5416 106.498 16.8088 105.765 16.9253C105.588 16.9527 105.411 16.9732 105.234 16.9869C105.064 17.0006 104.91 17.0075 104.773 17.0075H100.756V22.6294H99.3126ZM100.756 15.5686H104.733C104.851 15.5686 104.989 15.5617 105.146 15.548C105.303 15.5343 105.457 15.5104 105.608 15.4761C106.092 15.3665 106.488 15.1575 106.796 14.8492C107.11 14.5408 107.342 14.1743 107.493 13.7495C107.65 13.3246 107.729 12.8827 107.729 12.4236C107.729 11.9646 107.65 11.5226 107.493 11.0978C107.342 10.6662 107.11 10.2962 106.796 9.98782C106.488 9.67949 106.092 9.47051 105.608 9.36089C105.457 9.32663 105.303 9.30607 105.146 9.29922C104.989 9.28552 104.851 9.27867 104.733 9.27867H100.756V15.5686Z" fill="#0E121B"/>
    <path d="M111.363 22.6294V7.82951H112.806V21.1802H119.523V22.6294H111.363Z" fill="#0E121B"/>
    <path d="M126.717 22.9377C125.636 22.9377 124.681 22.7047 123.849 22.2388C123.024 21.7729 122.376 21.1151 121.905 20.2655C121.433 19.4159 121.198 18.4224 121.198 17.285V7.83979L122.651 7.82951V17.0897C122.651 17.8571 122.775 18.5217 123.024 19.0836C123.273 19.6386 123.597 20.0976 123.996 20.4608C124.402 20.8171 124.844 21.0809 125.322 21.2522C125.8 21.4234 126.265 21.5091 126.717 21.5091C127.175 21.5091 127.643 21.4234 128.121 21.2522C128.599 21.0809 129.038 20.8171 129.437 20.4608C129.836 20.0976 130.16 19.6386 130.409 19.0836C130.658 18.5217 130.782 17.8571 130.782 17.0897V7.82951H132.236V17.285C132.236 18.4224 132 19.4159 131.529 20.2655C131.057 21.1083 130.406 21.766 129.574 22.2388C128.749 22.7047 127.797 22.9377 126.717 22.9377Z" fill="#0E121B"/>
    <path d="M139.304 22.9377C138.315 22.9377 137.425 22.7664 136.633 22.4238C135.847 22.0744 135.196 21.581 134.679 20.9438C134.168 20.3066 133.834 19.5495 133.677 18.6725L135.15 18.4155C135.386 19.3748 135.883 20.1285 136.643 20.6766C137.402 21.2179 138.309 21.4885 139.363 21.4885C140.057 21.4885 140.679 21.3755 141.229 21.1494C141.779 20.9164 142.211 20.5875 142.525 20.1627C142.839 19.7379 142.996 19.2343 142.996 18.6519C142.996 18.2682 142.931 17.9427 142.8 17.6755C142.669 17.4015 142.496 17.1753 142.279 16.9972C142.063 16.8191 141.828 16.6717 141.572 16.5553C141.317 16.4319 141.068 16.3326 140.826 16.2572L137.242 15.1369C136.81 15.0068 136.41 14.8423 136.044 14.6436C135.677 14.4381 135.356 14.1948 135.081 13.9139C134.813 13.6261 134.603 13.2904 134.453 12.9067C134.302 12.523 134.227 12.0845 134.227 11.5911C134.227 10.7552 134.436 10.0324 134.855 9.42255C135.274 8.81274 135.847 8.3434 136.574 8.01451C137.301 7.68563 138.129 7.52461 139.058 7.53146C140.001 7.53146 140.842 7.70618 141.582 8.05562C142.329 8.40506 142.944 8.90524 143.428 9.55616C143.913 10.2002 144.234 10.9642 144.391 11.8481L142.879 12.1359C142.78 11.4918 142.554 10.9334 142.201 10.4606C141.847 9.98782 141.396 9.62468 140.846 9.37116C140.302 9.1108 139.703 8.97719 139.049 8.97033C138.414 8.97033 137.847 9.08339 137.35 9.3095C136.859 9.52876 136.469 9.83023 136.181 10.2139C135.893 10.5976 135.749 11.0361 135.749 11.5295C135.749 12.0297 135.88 12.4305 136.142 12.732C136.41 13.0334 136.738 13.2698 137.124 13.4411C137.517 13.6124 137.896 13.7529 138.263 13.8625L141.032 14.7156C141.32 14.7978 141.658 14.9177 142.044 15.0753C142.437 15.226 142.816 15.4453 143.183 15.733C143.556 16.014 143.864 16.384 144.106 16.843C144.348 17.3021 144.469 17.8845 144.469 18.5902C144.469 19.2754 144.339 19.8886 144.077 20.4299C143.821 20.9712 143.458 21.4269 142.987 21.7969C142.522 22.1669 141.975 22.4478 141.347 22.6396C140.725 22.8383 140.044 22.9377 139.304 22.9377Z" fill="#0E121B"/>
    <defs>
    <filter id="filter0_d_391_4570" x="4.40156" y="0.8" width="14.6734" height="19.5895" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2.1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4570"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4570" result="shape"/>
    </filter>
    <filter id="filter1_d_391_4570" x="0.8" y="6.19062" width="29.4" height="12.6734" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1.6"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4570"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4570" result="shape"/>
    </filter>
    <filter id="filter2_d_391_4570" x="0.8" y="13.718" width="29.4" height="12.6734" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1.6"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4570"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4570" result="shape"/>
    </filter>
    <filter id="filter3_d_391_4570" x="4.46406" y="11.9895" width="14.6148" height="20.2105" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2.1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4570"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4570" result="shape"/>
    </filter>
    <filter id="filter4_d_391_4570" x="11.9211" y="11.9895" width="14.6148" height="20.2105" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2.1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4570"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4570" result="shape"/>
    </filter>
    <filter id="filter5_d_391_4570" x="11.9289" y="0.8" width="14.6734" height="19.5895" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2.1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.435294 0 0 0 0 0.827451 0 0 0 0.38 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4570"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4570" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_391_4570" x1="116.844" y1="7.53125" x2="116.844" y2="22.9377" gradientUnits="userSpaceOnUse">
    <stop stop-color="#85BBFF"/>
    <stop offset="1" stop-color="#2784FC"/>
    </linearGradient>
    </defs>
    </svg>
  )
}
