import React from "react";
import { useState } from "react";
import { MinisteringCompanionship } from "./MinisteringCompanionship";
import { AddMinisteringCompanionship } from "./AddMinisteringCompanionship";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";

export const MinisteringDistrict = ({ sessionInfo, unitDetails, zoomedOut, membersList, setMembersList, district, companionships, companions, assignments, updateMinisteringDistrict, createMinisteringCompanionship, deleteMinisteringCompanionship, createMinisteringAssignment, createMinisteringCompanion, updateMinisteringAssignment, updateMinisteringCompanion, deleteMinisteringAssignment, deleteMinisteringCompanion }) => {
  const [defaultMessage, setDefaultMessage] = useState(district.default_message);
  const [conditionalLocationClause, setConditionalLocationClause] = useState(district.conditional_location_clause);
  const [showPopover, setShowPopover] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  async function handleSave() {
    try {
      await fetch("/api/ministering/update_companion", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ministeringDistrictId: district.id, defaultMessage, conditionalLocationClause }),
      });
      updateMinisteringDistrict(district.id, defaultMessage, conditionalLocationClause);
      setShowPopover(false);

      // Show success toast
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error sending SMS", error);
      setShowPopover(false);
    }
  }

  const popover = showPopover && (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 3000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        width: '450px',
      }}>
        <div style={{ 
          margin: '10px',
          fontSize: '16px',
          fontWeight: 'bold',
          fontStyle: 'italic',
          color: 'black',
        }}>
          {unitDetails.language === "es"
            ? "Este mensaje se copiará a tu aplicación de mensajería cuando crees un chat grupal de los compañeros en un compañerismo.\n\n<NOMBRES> se reemplazará con los nombres."
            : "This message will copy over to your messaging app when you create a group chat of the companions in a companionship.\n\n<NAMES> will be replaced with the first names."}
        </div>
        <textarea
          value={defaultMessage}
          onChange={(e) => setDefaultMessage(e.target.value)}
          placeholder={unitDetails.language === "es" ? "Escribir mensaje..." : "Write message..."}
          rows="8"
          style={{
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "90%",
            fontFamily: "inherit",
            fontSize: "16px",
            resize: "none",
            marginBottom: "2px"
          }}
        />
        <div style={{ 
          margin: '10px',
          fontSize: '16px',
          fontWeight: 'bold',
          fontStyle: 'italic',
          color: 'black',
        }}>
          {unitDetails.language === "es"
            ? "Lo siguiente se incluirá en el texto si los compañeros no son compañeros de habitación."
            : "The following will be included in the text if the companions are not roommates."}
        </div>
        <textarea
          value={conditionalLocationClause}
          onChange={(e) => setConditionalLocationClause(e.target.value)}
          placeholder={unitDetails.language === "es" ? "Escribir mensaje..." : "Write message..."}
          rows="4"
          style={{
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "90%",
            fontFamily: "inherit",
            fontSize: "16px",
            resize: "none",
            marginBottom: "2px"
          }}
        />
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          marginTop: "15px"
        }}>
          <button
            onClick={() => setShowPopover(false)}
            style={{
              padding: '4px 8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              background: 'white',
              fontSize: '14px',
              width: '100px',
            }}
          >
            {unitDetails.language === "es" ? "Cancelar" : "Cancel"}
          </button>
          <button
            disabled={(defaultMessage === district.default_message) && (conditionalLocationClause === district.conditional_location_clause)}
            onClick={handleSave}
            style={{
              padding: '4px 8px',
              border: 'none',
              borderRadius: '4px',
              background: '#2684FC',
              color: 'white',
              fontSize: '14px',
              width: '100px',
              opacity: (defaultMessage === district.default_message) && (conditionalLocationClause === district.conditional_location_clause) ? '0.65' : '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {unitDetails.language === "es" ? "Guardar" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );

  const successToast = (
    <div
      style={{
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        backgroundColor: '#22c55e',
        color: 'white',
        padding: '0.5rem 1rem',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        transform: showSuccess ? 'translateY(0)' : 'translateY(-1rem)',
        opacity: showSuccess ? 1 : 0,
        transition: 'all 0.3s ease',
        pointerEvents: showSuccess ? 'auto' : 'none',
        zIndex: 5000,
      }}
    >
      <svg
        style={{
          width: '1.25rem',
          height: '1.25rem',
        }}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
      <span>
        {unitDetails.language === "es" ? "Mensaje guardado" : "Message saved"}
      </span>
    </div>
  );

  return (
    <>
      <div className="organization-section">
        <h2
          style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px", position: 'relative' }}
        >
          {district.type} {district.name}
          <DropdownMenu>
            <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-2.5px", zIndex: 1000}} asChild>
              <i className="bx bx-dots-vertical-rounded companionship-three-dots" style={{ fontSize: "22px", color: "black" }}></i>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="dropdown-content"
              align="end"
              side="bottom"      
              style={{zIndex: 1001}}
            >
              <DropdownMenuRadioGroup>
                {["EQ_PRESIDENCY", "RS_PRESIDENCY", "MINISTERING"].includes(sessionInfo.accessLevel) && /Android|iPhone|iPad|iPod|Mac|Macintosh/.test(navigator.userAgent) &&
                  <DropdownMenuRadioItem
                    className="dropdown-item"
                    onClick={() => setShowPopover(true)}
                    style={{ fontSize: "16px", fontWeight: "normal" }}
                  >
                    <i className="bx bx-message-edit" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                    {defaultMessage ? "Edit" : "Create"} message template
                  </DropdownMenuRadioItem>
                }
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu >
        </h2>
        {
          companionships.filter(row => row.ministering_district_id === district.id).map((companionship, index) => (
            <MinisteringCompanionship
              key={index}
              companionship={companionship}
              sessionInfo={sessionInfo}
              unitDetails={unitDetails}
              zoomedOut={zoomedOut}
              membersList={membersList}
              setMembersList={setMembersList}
              type={district.type}
              defaultMessage={defaultMessage}
              conditionalLocationClause={conditionalLocationClause}
              companions={companions}
              assignments={assignments}
              deleteMinisteringCompanionship={deleteMinisteringCompanionship}
              createMinisteringAssignment={createMinisteringAssignment}
              createMinisteringCompanion={createMinisteringCompanion}
              updateMinisteringAssignment={updateMinisteringAssignment}
              updateMinisteringCompanion={updateMinisteringCompanion}
              deleteMinisteringAssignment={deleteMinisteringAssignment}
              deleteMinisteringCompanion={deleteMinisteringCompanion}
            />
          ))
        }
        {!zoomedOut && <AddMinisteringCompanionship onAdd={createMinisteringCompanionship} districtId={district.id} />}
      </div>
      {popover}
      {successToast}
    </>
  );
};
