import Select from "react-select";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { formatName } from "./utils";
import { NoProfilePicture } from "./NoProfilePicture";

export const PlaceholderCard = ({
  calling,
  zoomedOut,
  sessionInfo,
  onRecommend,
  deletePendingCalling,
  membersList,
  unitDetails,
  defaultBorderColor = false,
  organizations,
}) => {
  const organization = organizations.find(org => org.id === calling.organization_id);
  const parentOrganizationId = organization?.parent_id;
  const parentOrganization = parentOrganizationId ? organizations.find(org => org.id === parentOrganizationId) : null;
  const [recommended, setRecommended] = useState(null);

  useEffect(() => {
    if (recommended) {
      onRecommend({ ...calling, recommended_id: recommended.value });
    }
  }, [recommended]);

  const priorityOptions = membersList
  .filter((member) => member.is_prioritized)
  .map((member) => ({ ...member, label: formatName(member.label) }))
  .sort((a, b) => a.label.localeCompare(b.label));

  const otherOptions = membersList
    .filter((member) => !member.is_prioritized)
    .map((member) => ({ ...member, label: formatName(member.label) }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Separate and sort members into two groups: prioritized and non-prioritized
  const groupedOptions = priorityOptions.length ? [
    {
      label: unitDetails.language === "es" ? "Prioridad" : "Priority",
      options: priorityOptions
    },
    {
      label: unitDetails.language === "es" ? "Otros" : "Other",
      options: otherOptions
    },
  ] : membersList.map((member) => ({ ...member, label: formatName(member.label) })).sort((a, b) => a.label.localeCompare(b.label));

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ display: "flex", alignItems: "center", padding: "2px", cursor: "pointer", borderBottom: "0.5px solid #e0e0e0" }}>
        <div style={{ width: "50px", height: "50px", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {data.member_photo || data.household_photo ? <img src={data.member_photo || data.household_photo} alt={data.label} style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "5px", border: "0.5px solid #B0B5C0" }} /> : <NoProfilePicture />}
        </div>
        <span style={{ flex: 1, textAlign: "left" }}>{data.label}</span>
      </div>
    );
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "15px",
      textAlign: "left",
      margin: "6px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "225px",
      left: "50%",
      transform: "translateX(-50%)",
      maxHeight: "200px",
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
  };

  if (zoomedOut && ["EQ_PRESIDENCY", "RS_PRESIDENCY", "SS_PRESIDENCY"].includes(sessionInfo.accessLevel)) {
    return (
      <div
        className="member-card"
        style={{
          border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#74B7FF"}`,
          display: "flex", 
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger
            style={{
              cursor: "pointer",
              position: "absolute", 
              top: "-1px",
              right: "-7px",
              zIndex: 1000,
            }}
            asChild
          >
            <i
              className="bx bx-dots-vertical-rounded three-dots"
              style={{ fontSize: "22px" }}
            ></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"
            style={{ zIndex: 1000 }}
          >
            <DropdownMenuRadioGroup>
              <DropdownMenuRadioItem
                className="dropdown-item"
                onClick={() => deletePendingCalling(calling.id)}
                style={{ fontSize: "16px" }}
              >
                <i className="bx bx-trash" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                {unitDetails.language === "es" ? "Eliminar" : "Delete"}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <Select
          value={recommended}
          onChange={(option) => setRecommended(option)}
          options={groupedOptions}
          placeholder={unitDetails.language === "es" ? "Recomendar" : "Recommend"}
          styles={selectStyles}
          components={{ Option: CustomOption }}
        />
        <div style={{ flex: 1 }} />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
          {parentOrganization && (
            <>
              <div style={{ fontSize: "13px", fontStyle: "italic" }}>{parentOrganization.name}</div>
              <i className="bx bx-chevrons-down" style={{ fontSize: "13px" }}></i>
            </>
          )}
          <div style={{ fontSize: "13px", fontStyle: "italic" }}>{organization?.name}</div>
          <i className="bx bx-chevrons-down" style={{ fontSize: "13px" }}></i>
          <div style={{ fontSize: "20px", fontStyle: "italic" }}>{calling.name}</div>
        </div>
        <div style={{ flex: 1 }} />
      </div>
    )
  }

  if (zoomedOut) {
    return (
      <div
        className="member-card"
        style={{
          border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#74B7FF"}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
          {parentOrganization && (
            <>
              <div style={{ fontSize: "13px", fontStyle: "italic" }}>{parentOrganization.name}</div>
              <i className="bx bx-chevrons-down" style={{ fontSize: "13px" }}></i>
            </>
          )}
          <div style={{ fontSize: "13px", fontStyle: "italic" }}>{organization?.name}</div>
          <i className="bx bx-chevrons-down" style={{ fontSize: "13px" }}></i>
          <div style={{ fontSize: "20px", fontStyle: "italic" }}>{calling.name}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="member-card"
      style={{
        border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#74B7FF"}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <DropdownMenu>
        <DropdownMenuTrigger
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "-1px",
            right: "-7px",
            zIndex: 1000,
          }}
          asChild
        >
          <i
            className="bx bx-dots-vertical-rounded three-dots"
            style={{ fontSize: "22px" }}
          ></i>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="dropdown-content"
          align="end"
          side="bottom"
          style={{ zIndex: 1000 }}
        >
          <DropdownMenuRadioGroup>
            <DropdownMenuRadioItem
              className="dropdown-item"
              onClick={() => deletePendingCalling(calling.id)}
              style={{ fontSize: "16px" }}
            >
              <i className="bx bx-trash" style={{ marginRight: "5px", fontSize: "16px" }}></i>
              {unitDetails.language === "es" ? "Eliminar" : "Delete"}
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <Select
        value={recommended}
        onChange={(option) => setRecommended(option)}
        options={groupedOptions}
        placeholder={unitDetails.language === "es" ? "Recomendar" : "Recommend"}
        styles={selectStyles}
        components={{ Option: CustomOption }}
      />
      <div style={{ flex: 1 }} />
      <div style={{ width: "240px", display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
        {parentOrganization && (
          <>
            <div style={{ fontSize: "16px", fontStyle: "italic" }}>{parentOrganization.name}</div>
            <i className="bx bx-chevrons-down" style={{ fontSize: "16px" }}></i>
          </>
        )}
        <div style={{ fontSize: "16px", fontStyle: "italic" }}>{organization?.name}</div>
        <i className="bx bx-chevrons-down" style={{ fontSize: "16px" }}></i>
        <div style={{ fontSize: "24px", fontStyle: "italic" }}>{calling.name}</div>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
};