import { useRef } from 'react';
import { usePDF } from 'react-to-pdf';

export const SundayProgram = ({ sustainings }) => {
  const { toPDF, targetRef } = usePDF({
    filename: 'sunday-program.pdf',
    page: { 
      margin: 20,
      format: 'letter',
      orientation: 'portrait'
    }
  });

  return (
    <div className="container">
      <button 
        onClick={() => toPDF()}
        className="download-button"
      >
        Download PDF
      </button>

      <div 
        ref={targetRef} 
        className="page-preview"
      >
        <h1>Sacrament meeting - {(() => {
          const today = new Date();
          const daysUntilSunday = 7 - today.getDay();
          const nextSunday = new Date(today);
          nextSunday.setDate(today.getDate() + daysUntilSunday);
          return nextSunday.toLocaleDateString();
        })()}</h1>

        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Calling</th>
            </tr>
          </thead>
          <tbody>
            {sustainings.map((sustaining, index) => (
              <tr key={index}>
                <td>{sustaining.recommended_name}</td>
                <td>{sustaining.calling_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};