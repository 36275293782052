import { useState, useEffect, useRef } from "react";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

export const TempleRecommends = ({ unitDetails }) => {
  const initialLoad = useRef(true);
  const [messageTemplate, setMessageTemplate] = useState("");
  const [recipientTitle, setRecipientTitle] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiButtonRef = useRef(null);

  async function fetchTempleRecommendNotificationDetails() {
    try {
      const res = await fetch("/api/get_temple_recommend_notification_details")
      const data = await res.json();
      setRecipientTitle(data.temple_recommend_notification_recipient_title);
      setMessageTemplate(data.temple_recommend_message_template);
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  if (initialLoad.current) {
    const loadData = async () => {
      await fetchTempleRecommendNotificationDetails();
    };

    loadData();
    initialLoad.current = false;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && 
          !emojiPickerRef.current.contains(event.target) &&
          !emojiButtonRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  async function handleSave() {
    const res = await fetch("/api/update_temple_recommend_notification_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ messageTemplate }),
    });

    // Show success toast
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  }

  const handleEmojiSelect = (emoji) => {
    setMessageTemplate(prev => prev + emoji.native);
    setShowEmojiPicker(false);
  };

  const successToast = (
    <div
      style={{
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        backgroundColor: '#22c55e',
        color: 'white',
        padding: '0.5rem 1rem',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        transform: showSuccess ? 'translateY(0)' : 'translateY(-1rem)',
        opacity: showSuccess ? 1 : 0,
        transition: 'all 0.3s ease',
        pointerEvents: showSuccess ? 'auto' : 'none',
        zIndex: 5000,
      }}
    >
      <svg
        style={{
          width: '1.25rem',
          height: '1.25rem',
        }}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
      <span>
        {unitDetails.language === "es" ? "Guardado" : "Saved"}
      </span>
    </div>
  );

  return (
    <>
      <div style={{
        width: '300px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <div style={{ fontSize: "17px", fontWeight: "600", display: "flex", width: "100%", gap: "13px", justifyContent: "space-between" }}>
          <div style={{ color: "black" }}>{unitDetails.language === "es" ? "Plantilla del mensaje" : "Message template"}</div>
          <div style={{ display: "flex", gap: "13px" }}>
            <i
              className="bx bx-smile communication-icon" 
              style={{ 
                fontSize: "26px", 
                cursor: "pointer",
                position: "relative",
              }}
              ref={emojiButtonRef}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              title={unitDetails.language === "es" ? "Añadir emoji" : "Add emoji"}
            />
            {showEmojiPicker && (
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '-27px',
                  zIndex: 1000,
                }}
                ref={emojiPickerRef}
              >
                <Picker 
                  data={data}
                  onEmojiSelect={handleEmojiSelect}
                  theme="dark"
                  previewPosition="none"
                  skinTonePosition="none"
                />
              </div>
            )}
            <button
              onClick={handleSave}
              style={{
                padding: '4px 8px',
                border: 'none',
                borderRadius: '4px',
                background: '#2684FC',
                color: 'white',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {unitDetails.language === "es" ? "Guardar" : "Save"}
            </button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", borderRadius: "4px", border: "1px solid #ccc", marginTop: "10px", alignItems: "flex-start" }}>
          <textarea
            value={messageTemplate}
            onChange={(e) => setMessageTemplate(e.target.value)}
            maxLength={300}
            placeholder={unitDetails.language === "es" ? "Plantilla del mensaje" : "Message template"}
            rows="8"
            style={{
              width: "100%",
              fontFamily: "inherit",
              fontSize: "16px",
              resize: "none",
              border: "none",
              outline: "none",
              boxSizing: "border-box",
              marginTop: "8px",
              marginBottom: "8px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          />
        </div>
        <div style={{ fontSize: '14px', fontStyle: "italic", gap: "4px", marginTop: "10px", color: "black" }}>
          <span>This is the template for the message that will be copied into {recipientTitle}'s texts with the person. &lt;NAME&gt; will be replaced with the person's first name.</span>
        </div>
      </div>
      {successToast}
    </>
  )
}