import { useState, useEffect } from "react";
import ShuffleRoundedIcon from '@mui/icons-material/ShuffleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import * as Tabs from '@radix-ui/react-tabs';

export const Flashcards = ({ unitDetails, sessionInfo, membersList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [mode, setMode] = useState('flashcard');
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  
  const filteredMembers = membersList.filter(member => {
    const hasPhoto = member.member_photo || member.household_photo;
    if (!hasPhoto) return false;

    if (sessionInfo.accessLevel === "EQ_PRESIDENCY") {
      return member.gender === "M";
    }
    if (sessionInfo.accessLevel === "RS_PRESIDENCY") {
      return member.gender === "F"; 
    }
    return true;
  });

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space' && mode === 'flashcard' && !isTransitioning) {
        event.preventDefault();
        setIsFlipped(!isFlipped);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFlipped, mode, isTransitioning]);

  const getMultipleChoiceOptions = (index) => {
    const currentMember = filteredMembers[index];
    const otherMembers = filteredMembers
      .filter((m, i) => i !== index && m.gender === currentMember.gender)
      .sort(() => Math.random() - 0.5)
      .slice(0, 2);
    
    const options = [...otherMembers, currentMember]
      .sort(() => Math.random() - 0.5)
      .map(m => ({
        name: `${m.name.split(',')[1].trim().split(' ')[0]} ${m.name.split(',')[0]}`,
        isCorrect: m === currentMember
      }));
    
    return options;
  };

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
    setShowFeedback(true);
    
    if (answer.isCorrect) {
      setTimeout(() => {
        handleNext();
      }, 500);
    }
  };

  const handleNext = (isCorrect = null) => {
    setIsTransitioning(true);
    setIsFlipped(false);
    setSelectedAnswer(null);
    setShowFeedback(false);

    if (mode === 'flashcard' && isCorrect !== null) {
      if (isCorrect) {
        setCorrectCount(prev => prev + 1);
      } else {
        setIncorrectCount(prev => prev + 1);
      }
    }

    setCurrentIndex((prevIndex) => 
      prevIndex === filteredMembers.length - 1 ? 0 : prevIndex + 1
    );
    if (mode === 'multiple-choice') {
      setCurrentOptions(getMultipleChoiceOptions(currentIndex === filteredMembers.length - 1 ? 0 : currentIndex + 1));
    }
    setTimeout(() => {
      setIsTransitioning(false);
    }, 600);
  };

  const handlePrevious = () => {
    setIsTransitioning(true);
    setIsFlipped(false);
    setSelectedAnswer(null);
    setShowFeedback(false);
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? filteredMembers.length - 1 : prevIndex - 1
    );
    if (mode === 'multiple-choice') {
      setCurrentOptions(getMultipleChoiceOptions(currentIndex === 0 ? filteredMembers.length - 1 : currentIndex - 1));
    }
    setTimeout(() => {
      setIsTransitioning(false);
    }, 600);
  };

  const handleFlip = () => {
    if (!isTransitioning && mode === 'flashcard') {
      setIsFlipped(!isFlipped);
    }
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setCurrentIndex(0);
    setCorrectCount(0);
    setIncorrectCount(0);
    if (newMode === 'multiple-choice') {
      setCurrentOptions(getMultipleChoiceOptions(0));
    }
  };

  if (filteredMembers.length === 0) {
    return <div>No members with photos found</div>;
  }

  const currentMember = filteredMembers[currentIndex];

  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
        <div style={{ marginBottom: '20px' }}>
          <Tabs.Root defaultValue={"flashcard"} style={{ display: 'flex', gap: '8px' }}>
            <Tabs.List style={{ display: 'flex', backgroundColor: "#B0B5C0", borderRadius: '6px', height: "32px" }}>
              <Tabs.Trigger
                value={true}
                onClick={() => handleModeChange('flashcard')}
                style={{
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  border: 'none',
                  borderRadius: '4px',
                  color: 'black',
                  backgroundColor: mode === 'flashcard' ? 'white' : '#B0B5C0',
                  cursor: 'pointer',
                  margin: '2px',
                  transition: 'background-color 0.2s',
                }}
              >
                <strong>{unitDetails.language === "es" ? "Modo clásico" : "Classic mode"}</strong>
              </Tabs.Trigger>
              <Tabs.Trigger
                value={false}
                onClick={() => handleModeChange('multiple-choice')}
                style={{
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  border: 'none',
                  borderRadius: '4px',
                  color: 'black',
                  backgroundColor: mode === 'multiple-choice' ? 'white' : '#B0B5C0',
                  cursor: 'pointer',
                  margin: '2px',
                  transition: 'background-color 0.2s',
                }}
              >
                <strong>{unitDetails.language === "es" ? "Opción múltiple" : "Multiple choice"}</strong>
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
        </div>
        {mode === 'flashcard' && (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            width: '100%', 
            fontSize: '14px',
          }}>
            <div style={{ color: "#FFA56F", fontWeight: "600" }}>
              <span style={{ border: "1px solid #FFA56F", padding: "2px 12px", borderRadius: "10px", marginRight: "6px" }}>{incorrectCount}</span>
              Still learning
            </div>
            <div style={{ color: "#1AD7B8", fontWeight: "600" }}>
              Know
              <span style={{ border: "1px solid #1AD7B8", padding: "2px 12px", borderRadius: "10px", marginLeft: "6px" }}>{correctCount}</span>
            </div>
          </div>
        )}
        {mode === 'flashcard' ? (
          <div
            className={`flashcard ${isFlipped ? 'flipped' : ''} ${isTransitioning ? 'transitioning' : ''}`}
            onClick={handleFlip}
          >
            <div className="flashcard-front">
              <img 
                src={currentMember.member_photo || currentMember.household_photo} 
              />
            </div>
            <div className="flashcard-back">
              <div className="text-center">
                <h2>{currentMember.name.split(',')[1].trim().split(' ')[0]}</h2>
                <h2>{currentMember.name.split(',')[0]}</h2>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
            <img 
              src={currentMember.member_photo || currentMember.household_photo}
              style={{ width: '300px', height: '300px', objectFit: 'cover', borderRadius: '8px', border: '3px solid #B0B5C0' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
              {currentOptions.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerSelect(option)}
                  disabled={showFeedback}
                  style={{
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    background: showFeedback
                      ? option.isCorrect 
                        ? '#d4edda' 
                        : selectedAnswer === option 
                          ? '#f8d7da'
                          : '#fff'
                      : '#fff',
                    cursor: showFeedback ? 'default' : 'pointer'
                  }}
                >
                  {option.name}
                </button>
              ))}
            </div>
          </div>
        )}

        <div style={{ display: 'flex', gap: '20px', color: 'black', alignItems: 'center' }}>
          {mode === 'multiple-choice' && (
            <div style={{ display: 'flex', gap: '20px', color: 'black', alignItems: 'center' }}>
              <button onClick={handlePrevious} style={{ border: "none", background: "none", display: "flex", alignItems: "center" }}><NavigateBeforeRoundedIcon style={{ fontSize: '24px' }} /></button>
              <span style={{ display: "flex", alignItems: "center", fontSize: '16px' }}>{currentIndex + 1} / {filteredMembers.length}</span>
              <button onClick={handleNext} style={{ border: "none", background: "none", display: "flex", alignItems: "center" }}><NavigateNextRoundedIcon style={{ fontSize: '24px' }} /></button>
            </div>
          )}
          {mode === 'flashcard' && (
            <>
              <button 
                onClick={() => handleNext(false)} 
                style={{ 
                  border: "none", 
                  background: "none", 
                  display: "flex", 
                  alignItems: "center",
                  color: "#FFA56F"
                }}
              >
                <CloseRoundedIcon style={{ fontSize: '28px' }} />
              </button>
              <button 
                onClick={() => handleNext(true)} 
                style={{ 
                  border: "none", 
                  background: "none", 
                  display: "flex", 
                  alignItems: "center",
                  color: "#1AD7B8"
                }}
              >
                <CheckRoundedIcon style={{ fontSize: '28px' }} />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}