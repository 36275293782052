import { RoommateCard } from "./RoommateCard";
import { useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { formatName } from "./utils";

export const Roommates = ({ membersList, sessionInfo, unitDetails, setMembersList }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const groupedByLocation = membersList
    .filter(member => member.latitude != null && member.longitude != null)
    .reduce((acc, member) => {
      const key = `${member.latitude},${member.longitude}${member.apartment ? `-${member.apartment}` : ''}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(member);
      return acc;
    }, {});

  const sortedLocations = Object.entries(groupedByLocation)
    .map(([coords, members]) => ({
      coords,
      members,
      count: members.length,
      address: members[0].address_line_1,
      apartment: members[0].apartment
    }))
    .filter(location => location.count > 1)
    .sort((a, b) => {
      // First sort by address
      if (a.address < b.address) return -1;
      if (a.address > b.address) return 1;
      // Then sort by apartment number if they exist
      if (a.apartment && b.apartment) {
        return a.apartment - b.apartment;
      }
      return b.count - a.count;
    })
    .filter(location => 
      location.members.some(member => 
        formatName(member.name).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

  return (
  <>
    {membersList.length > 0 && <div style={{ position: "relative", display: "flex", gap: "10px", marginLeft: "20px", alignItems: "flex-start" }}>
      <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
        <SearchOutlinedIcon style={{ 
          position: "absolute",
          left: "12px",
          color: "#6b7280",
          fontSize: "20px"
        }}/>
        <input
          type="text"
          placeholder="Search members"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            padding: "8px 12px 8px 38px",
            fontSize: "14px", 
            border: "1px solid #ccc",
            borderRadius: "4px",
            width: "200px"
          }}
        />
      </div>
    </div>}
    <div className="roommates-container">
      {sortedLocations.map(location => {
        return (
          <div key={location.coords} className="roommates-section">
            <h2>{location.address}{location.apartment ? `, Apt ${location.apartment}` : ''}</h2>
            <div className="members">
              {location.members.map(member => (
                <RoommateCard
                  key={member.id}
                  member={member}
                  sessionInfo={sessionInfo}
                  unitDetails={unitDetails}
                  membersList={membersList}
                  setMembersList={setMembersList}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  </>
)}
